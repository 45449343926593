const config = {
	extCodes: [
		{ code: 'SA', text: '+966', length: 9, start: ['5'], emoji: "🇸🇦", flag: '/static/content/flag-saudi.png', regEx: '^(5)[0-9]{8}$', repExp: '^(00966|966|\\+966)', pHolder: '5X XXX XXXX' },
		{ code: 'AE', text: '+971', length: 9, start: ['5'], emoji: "🇦🇪", flag: '/static/content/flag-uae.png', regEx: '^(5)[0-9]{8}$', repExp: '^(00971|971|\\+971)', pHolder: '5X XXX XXXX' },
		{ code: 'BH', text: '+973', length: 8, start: ['3'], emoji: "🇧🇭", flag: '/static/content/flag-baharain.png', regEx: '^(3)[0-9]{7}$', repExp: '^(00973|973|\\+973)', pHolder: '3X XXX XXX' },
		{ code: 'OM', text: '+968', length: 8, start: ['7', '9'], emoji: "🇴🇲", flag: '/static/content/flag-oman.png', regEx: '^(7|9)[0-9]{7}$', repExp: '^(00968|968|\\+968)', pHolder: '7|9X XXX XXX' },
		{ code: 'KW', text: '+965', length: 8, start: ['5', '6', '9'], emoji: "🇰🇼", flag: '/static/content/flag-kuwait.png', regEx: '^(5|6|9)[0-9]{7}$', repExp: '^(00965|965|\\+965)', pHolder: '5|6|9X XXX XXX' },
		{ code: 'QA', text: '+974', length: 8, start: ['3', '5', '6','7'], emoji: "🇶🇦", flag: '/static/content/flag-qatar.png', regEx: '^(3|5|6|7)[0-9]{7}$', repExp: '^(00974|974|\\+974)', pHolder: '3|5|6|7X XXX XXX' },
		{ code: 'US', text: '+1', length: 10, start: ['2', '3', '4', '5', '6', '7', '8', '9'], emoji: "🇺🇸", flag: '/static/content/flag-us.png', regEx: '^(2|3|4|5|6|7|8|9)[0-9]{9}$', repExp: '^(001|1|\\+1)', pHolder: 'XXX XXX XXXX' },
		{ code: 'GB', text: '+44', length: 10, start: ['7'], emoji: "🇬🇧", flag: '/static/content/flag-uk.png', regEx: '^(7)[0-9]{9}$', repExp: '^(0044|44|\\+44)', pHolder: '7XXX XXX XXX' },
		{ code: 'FR', text: '+33', length: 9, start: ['6', '7'], emoji: "🇫🇷", flag: '/static/content/flag-fr.png', regEx: '^(6|7)[0-9]{8}$', repExp: '^(0033|33|\\+33)', pHolder: '6|7 XX XX XX XX' },
		{ code: 'ES', text: '+34', length: 9, start: ['6'], emoji: "🇪🇸", flag: '/static/content/flag-sp.png', regEx: '^(6)[0-9]{8}$', repExp: '^(0034|34|\\+34)', pHolder: '6XX XXX XXX' },
		{ code: 'MA', text: '+212', length: 9, start: ['6'], emoji: "🇲🇦", flag: '/static/content/flag-mr.png', regEx: '^(6)[0-9]{8}$', repExp: '^(00212|212|\\+212)', pHolder: '6XX XXX XXX' },
		{ code: 'JO', text: '+962', length: 9, start: ['7'], emoji: "🇯🇴", flag: '/static/content/flag-jd.png', regEx: '^(7)[0-9]{8}$', repExp: '^(00962|962|\\+962)', pHolder: '7X XXX XXXX' },
		{ code: 'EG', text: '+20', length: 10, start: ['1'], emoji: "🇪🇬", flag: '/static/content/flag-eg.png', regEx: '^(1)[0-9]{9}$', repExp: '^(0020|20|\\+20)', pHolder: '1X XXXX XXXX' },
		// { code: 'IN', text: '+91', length: 10, start: ['6', '7', '8', '9'], emoji: "", flag: '/static/content/flag-india.png', regEx: '(6|7|8|9)[0-9]{9}$', repExp: '^(0091|91|\\+91)', pHolder: '9XXXX XXXXX'}
	],
	lengthCheck: (val = '', lens = [4, 50]) => {
		if (val.length < lens[0]) {
			return lens[0];
		}
		if (val.length > lens[1]) {
			return lens[1];
		} else {
			return null;
		}
	},
};

export default config;
